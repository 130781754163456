import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import Header from "../components/Header/Header"
import Section from "../components/Section/Section"
import ProductList from "../components/ProductList/ProductList"
import ProductRemark from "../components/ProductRemark/ProductRemark"
import ListItem from "../components/ListItem/ListItem"

import get from "lodash/get"
import media from "../utils/media"
import styled from "styled-components"
import background from "../../static/assets/footer-bg.png"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import ContactAddress from "../components/ContactAddress/ContactAddress"

const ProductCategory = styled.div`
  ${media.mobile`
    width: 90%;
    margin: auto;
    margin-top: 0px;
  `}
  display: flex;
  flex-direction: column;
  flex: .7;
  justify-content: flex-start;
  width: 50%;
  margin-top: 40px;
`

const SelectedProduct = styled.div`
  ${media.mobile`
    margin-left: 0px;
    margin-top: 0px;
  `}
  flex: .3;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 40px;
`

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProducts: undefined,
    }
  }

  onSelectItem(item, selectedCode) {
    const { code, specialCode } = item
    const isSpecial = specialCode === selectedCode
    this.setState((prevState) => {
      const { selectedProducts } = prevState
      if (selectedProducts && selectedProducts.find((product) => product.code === code || specialCode && (product.specialCode === specialCode))) {
        return { selectedProducts }
      }
      const selectedItem = {
        ...item,
        isSpecial,
      }
      return {
        selectedProducts: selectedProducts ? [...selectedProducts, selectedItem] : [selectedItem],
      }
    })
  }

  onRemoveItem(item) {
    this.setState((prevState) => {
      const { selectedProducts } = prevState
      return {
        selectedProducts: selectedProducts.filter((selected) => item.code !== selected.code),
      }
    })
  }

  render() {
    console.log(this.props)
    const products = get(this, "props.data", [])
    const { selectedProducts } = this.state
    const light = products.filter(({ node }) => node.group === "Light")
    const medium = products.filter(({ node }) => node.group === "Medium")
    const dark = products.filter(({ node }) => node.group === "Dark")
    const other = products.filter(({ node }) => node.group === "Other")

    return (
      <div>
        <Header />
        <Section
          mobileColumn
          style={{
            background:
              "linear-gradient(to bottom, rgba(255,216,150,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%)",
          }}
        >
          <ProductCategory>
            <ProductList products={light} title={<FormattedMessage id="light_tone" />}
                         onSelect={(item, code) => this.onSelectItem(item, code)} />
            <ProductList products={medium} title={<FormattedMessage id="medium_tone" />}
                         onSelect={(item, code) => this.onSelectItem(item, code)} />
            <ProductList products={dark} title={<FormattedMessage id="dark_tone" />}
                         onSelect={(item, code) => this.onSelectItem(item, code)} />
            <ProductList products={other} title={<FormattedMessage id="others" />}
                         onSelect={(item, code) => this.onSelectItem(item, code)} />
            <ProductRemark />
          </ProductCategory>
          <SelectedProduct>
            <h2 style={{ textAlign: "center" }}>
              <FormattedMessage id="selected_products" />
            </h2>
            {selectedProducts && selectedProducts.map((product) => (
              <ListItem item={product} special={product.isSpecial} onRemove={(item) => this.onRemoveItem(item)} />
            ))}
          </SelectedProduct>
        </Section>
        <Section
          style={{
            paddingBottom: "15%",
            paddingTop: 0,
            background: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "contain",
          }}
        >
          <ContactAddress />
        </Section>
      </div>
    )
  }
}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProduct(sort: {order: ASC, fields: code}) {
          edges {
            node {
              contentful_id
              node_locale
              name
              group
              price
              code
              specialPrice
              specialCode
              image {
                description
                fluid(maxWidth: 300, resizingBehavior: SCALE, quality: 80) {
                 ...GatsbyContentfulFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulProduct.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <Product {...props} data={data} />
    }}
  />
))

