import React, { Component } from "react"
import Img from "gatsby-image"
import Input from "../Input/Input"
import Button from "../Button/Button"
import { FiPlus, FiX } from "react-icons/fi"
import { FormattedMessage } from "gatsby-plugin-intl"

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: [0],
      price: props.special ? props.item.specialPrice : props.item.price,
    }
  }

  render() {
    const { item, onRemove, special } = this.props
    const { inputs, price } = this.state
    const totalWeight = inputs.reduce((total, value) => total + value).toFixed(1)

    return (
      <React.Fragment>
        <div
          style={{ display: "flex", justifyContent: "space-between", position: "relative" }}
        >
          <Img
            alt={item.image.description}
            style={{ width: 65, margin: "auto", flex: .5 }}
            fluid={item.image.fluid}
          />
          <div style={{ flex: 1.5, padding: 8 }}>
            <h3 style={{ margin: "0px !important" }}>{item.name}</h3>
            {item.specialCode ?
              <select
                value={price}
                onChange={({ target }) => {
                  this.setState({ price: target.value })
                }}
              >
                <option value={item.price} selected={!special}>{item.code}</option>
                <option value={item.specialPrice} selected={special}>{item.specialCode}</option>
              </select>
              :
              <p style={{ margin: 0 }}>{item.code}</p>
            }
            <p style={{ margin: 0, fontSize: ".8em" }}>(฿ {price} / <FormattedMessage id="kg" />)</p>
          </div>
          <div style={{ flex: 1, padding: 8, marginTop: "0.8rem", textAlign: "right" }}>
            <p style={{ margin: 0 }}>{totalWeight} <FormattedMessage id="kg" /></p>
            <h4 style={{
              margin: 0,
              color: "#E74E0B",
              marginTop: "0.8rem",
            }}>฿ {Number(price * totalWeight).toLocaleString()}</h4>
          </div>
          <a onClick={() => onRemove(item)} style={{ position: "absolute", right: 0, color: "red" }}>
            <FiX />
          </a>
        </div>
        <div style={{ display: "flex", padding: 8, flexWrap: "wrap", flexDirection: "column" }}>
          <p style={{ marginBottom: 0 }}><FormattedMessage id="fabric_weight" /></p>
          {inputs.map((input, index) =>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 4 }}>
              <label htmlFor={1} style={{ fontSize: ".75em", fontWeight: "bold" }}><FormattedMessage id="fold_no" /> {index}</label>
              <div>
                <Input
                  style={{ width: 100, padding: 5, margin: 1 }}
                  type="number"
                  name={1}
                  onChange={(e) => {
                    inputs[index] = Number(e.target.value)
                    return this.setState({ inputs })
                  }} />
                <span style={{ fontSize: ".75em" }}> <FormattedMessage id="kg" /> </span>
              </div>
            </div>,
          )}
          <Button icon style={{ marginLeft: 8, fontWeight: "bold", margin: "auto" }}
                  onClick={() => this.setState({ inputs: [...inputs, 0] })}><FiPlus /></Button>
        </div>
      </React.Fragment>
    )
  }
}

export default ListItem
