import React, { Component } from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"

class ProductRemark extends Component {
  render() {
    const remark = get(this.props, "data")
    const { node } = remark[0]
    return (
      <div>
        <h2>
          <FormattedMessage id="remark" />
        </h2>
        <ol style={{ marginLeft: '.8rem'}}>
          {node.list.map((order) => (
            <li>{order}</li>
          ))}
        </ol>
      </div>
    )
  }
}

export default injectIntl((props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulRemark {
          edges {
            node {
              contentful_id
              node_locale
              name
              list
            }
          }
        }
      }
    `}
    render={(contentfulData) => {
      const edges = get(contentfulData, "allContentfulRemark.edges", [])
      const { intl: { locale } } = props
      const data = edges.filter(({ node: { node_locale } }) => locale === node_locale)
      return <ProductRemark data={data} {...props} />
    }}
  />
))
