import React from "react"
import Img from "gatsby-image"
import Button from "../Button/Button"
import Slider from "react-slick"
import styled from "styled-components"
import media, { sizes } from "../../utils/media"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FormattedMessage } from "gatsby-plugin-intl"

const Arrow = styled.div`
  width: 25px !important;
  height: 25px !important;
  &&::before {
    font-size: 25px;
    color: #E74E0B;
    opacity: 1;
  }
`

const ProductListWrapper = styled.div`
  ${media.mobile`
    margin-bottom: 40px;
    text-align: center;
  `}
`

const ProductCardWrapper = styled.div`
  ${media.mobile`
    font-size: 12px;
    padding: 8px;
  `}
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 2px 2px 6px 3px rgba(0,0,0,0.1);
  margin: 8px;
  padding: 16px;
  border-radius: 16px; 
`

const PriceButton = styled(Button)`
  ${media.mobile`
    min-width: auto;
    width: 100%;
    padding: 0px;
    margin: 0px;
  `}
  margin: auto;
  margin-bottom: 1em;
`

const ProductImg = styled(Img)`
  ${media.mobile`
    width: 80px;
  `}
  width: 100px;
  margin: auto;
`

const ProductCard = ({ item, onSelect }) => (
  <ProductCardWrapper>
    <ProductImg
      alt={item.image.description}
      fluid={item.image.fluid}
    />
    <p style={{ fontWeight: "bold", marginBottom: ".4rem", marginTop: ".8rem" }}>{item.name}</p>
    <div>
      <p style={{ marginBottom: 0, fontSize: ".8em" }}>{item.code}</p>
      <PriceButton onClick={() => onSelect(item, item.code)}>฿ {item.price} / <FormattedMessage id="kg" /></PriceButton>
    </div>
    {item.specialCode &&
    <div>
      <p style={{ marginBottom: 0, fontSize: ".8em" }}>{item.specialCode}</p>
      <PriceButton onClick={() => onSelect(item, item.specialCode)}>฿ {item.specialPrice} / <FormattedMessage id="kg" /></PriceButton>
    </div>
    }
  </ProductCardWrapper>
)

const ProductList = ({ products, title, onSelect }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: products.length > 4 ? 4 : products.length,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: sizes.mobile,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          appendDots: dots => (
            <div
              style={{
                bottom: -50,
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
    ],
  }
  return (
    <ProductListWrapper>
      <h2>{title}</h2>
      <Slider {...settings}>
        {products.map(({ node }) => (
          <ProductCard item={node} onSelect={onSelect} />
        ))}
      </Slider>
    </ProductListWrapper>
  )
}

export default ProductList
